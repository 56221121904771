import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';

import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { defaultTheme } from '../styles/theme';
import { Amplify } from 'aws-amplify';
import nProgress from 'nprogress';
import { Router } from 'next/router';
import awsExports from '../src/updated-aws-exports';
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { TrackingProvider } from 'components/hooks/useTracking';
import { IntercomProvider } from 'react-use-intercom';
import { IntercomContent } from 'components/parts/IntercomContent';
import { AuthContext, useAuth } from 'components/hooks/useCurrentUser';

Amplify.configure({ ...awsExports, ssr: true });

const GlobalStyle = createGlobalStyle`
  html{
    padding: 0;
    margin: 0;
    font-family: "Museo Sans";
    color: ${({ theme }) => theme.text.default};
    font-size: 20px;
    height: 100%;
    -webkit-font-smoothing: antialiased;
  }
  
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.secondary};
  }

  a:hover {
    cursor:pointer;
  }

  button {
    cursor: pointer;
  }


  h1 {
    font-size: 44px;
    font-family: 'Crimson Text';
    font-weight: 600;
    margin-bottom: 12px;
  }

  body{
    padding: 0;
    margin: 0;
    min-height: 100vh;
  }

  #nprogress {
    --primary-color: #31B29E;
    pointer-events: none;
    .bar {
        background: var(--primary-color);
        position: fixed;
        z-index: 10031;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
    }
  }
`;

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

const INTERCOM_APP_ID = 'o249ggvl';

function MyApp({ Component, pageProps }: AppProps) {
    useEffect(() => {
        // TODO - improve this check, this should run in production
        if (process.env.NEXT_PUBLIC_APP_ENV === 'prod') {
            const HJID = 2848397;
            const HJSV = 6;
            hotjar.initialize(HJID, HJSV);
        }
    }, []);

    const isProd = process.env.NEXT_PUBLIC_APP_ENV === 'prod';

    const userContext = useAuth();

    return (
        <AuthContext.Provider value={userContext}>
            <IntercomProvider shouldInitialize={isProd} initializeDelay={1000} autoBoot={false} appId={INTERCOM_APP_ID}>
                <TrackingProvider>
                    <ThemeProvider theme={defaultTheme}>
                        <GlobalStyle />
                        <Component {...pageProps} />
                        <IntercomContent />
                    </ThemeProvider>
                </TrackingProvider>
            </IntercomProvider>
        </AuthContext.Provider>
    );
}
export default appWithTranslation(MyApp);
