export const defaultTheme = {
    text: { default: '#2C296A', contrast: '#FFFFFF', placeholder: '#979797' },
    colors: {
        main: '#2C296A',
        primary: '#31B29E',
        secondary: '#B1268E',
        danger: '#B23145',
        warning: '#FF7900',
        overlay: '#2C296A',
    },
    status: {
        Fulfilled: '#31B29E',
        Cancelled: '#B1268E',
        Pending: '#2C296A',
        Unfulfilled: '#FF921E',
        Initiated: '#B23145',
        Deleted: '#B23145'
    }
};

export const BREAKPOINT_SM = 576;
export const BREAKPOINT_MD = 768;
export const BREAKPOINT_LG = 992;
export const BREAKPOINT_XL = 1200;

export const Breakpoints = {
    sm: `only screen and (min-width: ${BREAKPOINT_SM}px)`,
    md: `only screen and (min-width: ${BREAKPOINT_MD}px)`,
    lg: `only screen and (min-width: ${BREAKPOINT_LG}px)`,
    xl: `only screen and (min-width: ${BREAKPOINT_XL}px)`
};

export type ThemeType = typeof defaultTheme;
