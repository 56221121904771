import { useCurrentUser } from 'components/hooks/useCurrentUser';
import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import delay from 'lodash/delay';

export const IntercomContent = () => {
    const user = useCurrentUser(false);

    const { boot } = useIntercom();
    const isProd = process.env.NEXT_PUBLIC_APP_ENV === 'prod';

    useEffect(() => {
        delay(() => {
            if (isProd && user !== undefined) {
                if (user) {
                    boot({
                        userId: user.id,
                        email: user.email,
                        name: `${user.firstName} ${user.lastName}`,
                        phone: user.phone ?? undefined,
                    });
                } else {
                    boot();
                }
            }
        }, 1000);
    }, [user, boot, isProd]);
    return null;
};
