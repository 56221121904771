import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Hub } from 'aws-amplify';
import Router from 'next/router';

export type UserAttributes = {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    isInternal: boolean;
    isDemo: boolean;
};

export const AuthContext = React.createContext<{
    user: UserAttributes | null | undefined;
}>({
    user: undefined,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseUser = (user: any) => {
    let isInternal = false;
    let isDemo = false;
    if (user.signInUserSession) {
        const groups: string[] = user.signInUserSession?.accessToken?.payload?.['cognito:groups'];
        isInternal = Boolean(groups?.find((x) => x === 'internal'));
        isDemo = Boolean(groups?.find((x) => x === 'demo'));
    }
    const attributes = user.attributes || user.signInUserSession?.idToken?.payload;
    return {
        email: attributes.email,
        firstName: attributes.given_name,
        lastName: attributes.family_name,
        phone: attributes.phone_number,
        id: attributes.sub,
        isInternal,
        isDemo,
    };
};

const useClearCookiesOnce = () => {
    useEffect(() => {
        try {
            const cookiesCleared = Boolean(localStorage.getItem('cookies-cleared-hack'));
            if (!cookiesCleared) {
                const x = document.cookie.split(';');
                x.forEach((item) => {
                    const [name] = item.split('=');
                    if (name.indexOf('CognitoIdentityServiceProvider') !== -1) {
                        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    }
                });
                localStorage.setItem('cookies-cleared-hack', 'true');
                console.log('cookies cleared');
            }
        } catch (e) {
            console.log('cookies clear error');
            console.error(e);
        }
    }, []);
};

export const useAuth = () => {
    const [user, setUser] = useState<UserAttributes | null | undefined>(undefined);

    // This is hack to clear cookies
    useClearCookiesOnce();

    useEffect(() => {
        const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                    setUser(parseUser(data));
                    break;
                case 'signOut':
                    setUser(null);
                    break;
                case 'cognitoHostedUI':
                    Router.push('/shipments');
                    break;
                case 'cognitoHostedUI_failure':
                    console.error('Sign in failure', data);
                    break;
            }
        });

        return unsubscribe;
    }, []);

    useEffect(() => {
        const getUserdata = async () => {
            try {
                const userData = await Auth.currentAuthenticatedUser();
                setUser(parseUser(userData));
            } catch (e) {
                setUser(null);
            }
        };
        getUserdata();
    }, []);

    return useMemo(
        () => ({
            user,
        }),
        [user]
    );
};

export const useCurrentUser = (redirect = false): UserAttributes | null | undefined => {
    const value = useContext(AuthContext);
    useEffect(() => {
        if (value.user === null && redirect) {
            Router.push('/');
        }
    }, [value.user, redirect]);
    return value.user;
};
