import awsExports from './aws-exports';
import { UniversalStorage } from '@aws-amplify/core';

//Check if you are in localhost or production
const isLocalhost = typeof window !== 'undefined' && window.location.hostname === 'localhost'

const isProd = process.env.NEXT_PUBLIC_APP_ENV === 'prod';


const signInURI = awsExports.oauth.redirectSignIn.split(',')

const signOutURI = awsExports.oauth.redirectSignOut.split(',')

const index = isLocalhost ? 0 : (isProd ? 2 : 1);

awsExports.oauth.redirectSignIn = signInURI[index]

awsExports.oauth.redirectSignOut = signOutURI[index];


// TODO - this is a hack to set sameSite lax for cookies
// To allow to preserve cookies when redirecting via Shopify callback
class CustomStorage extends UniversalStorage {
    protected setUniversalItem(key: string, value: string) {
        this.cookies.set(key, value, {
            path: '/',
            sameSite: 'lax',
            secure: isLocalhost ? false : true,
        });
    }
}

(awsExports as any)['Auth'] = {
    storage: new CustomStorage()
}

export default awsExports;


