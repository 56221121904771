/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://3buzkjhtqfgltdgmh3fuye6zmm.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:d52b8d74-b60f-4560-8eb7-328a08a52400",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_XHKvOvcTq",
    "aws_user_pools_web_client_id": "oopfuk1s2o25n1qf5i3lhd35e",
    "oauth": {
        "domain": "magicship-prod.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://dev.magicship.com/,https://magicship.com/",
        "redirectSignOut": "http://localhost:3000/,https://dev.magicship.com/,https://magicship.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "AmazonToShopify",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "shipment214453-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
