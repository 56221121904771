import { useRouter } from 'next/router';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { useIntercom } from 'react-use-intercom';

type PixelMethods = {
    pageView: () => void;
    completeRegistration: () => void;
    addToCart: () => void;
    purchase: (orderId: string, value: number, currency: string) => void;
};

const initialMethods: PixelMethods = {
    pageView: () => {
        /* noop */
    },
    completeRegistration: () => {
        /* noop */
    },
    addToCart: () => {
        /* noop */
    },
    purchase: () => {
        /* noop */
    },
};

const PixelContext = React.createContext(initialMethods);

const useInitPixel = () => {
    const router = useRouter();
    const [pixel, setPixel] = useState<PixelMethods>(initialMethods);
    const { trackEvent } = useIntercom();
    const addedToCartRef = useRef(false);
    useEffect(() => {
        const setupPixel = async () => {
            let username = '';
            let phone = '';
            try {
                const user = await Auth.currentAuthenticatedUser();
                phone = user.attributes.phone_number;
                username = user.getUsername();
            } catch (e) {}
            const { default: ReactPixel } = await import('react-facebook-pixel');
            ReactPixel.init(
                '811850036441324',
                username
                    ? ({
                          em: username,
                          ph: phone ?? undefined,
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      } as any)
                    : undefined,
                {
                    autoConfig: true,
                    debug: false,
                }
            );
            if (pixel === initialMethods) {
                setPixel(() => ({
                    pageView: () => {
                        ReactPixel.pageView();
                    },
                    completeRegistration: () => {
                        ReactPixel.track('CompleteRegistration', {});
                    },
                    addToCart: () => {
                        if (!addedToCartRef.current) {
                            ReactPixel.track('AddToCart');
                            addedToCartRef.current = true;
                        }
                    },
                    purchase: (orderId: string, value: number, currency: string) => {
                        ReactPixel.track('Purchase', { value, currency });
                        trackEvent('purchase', { value, currency, orderId });
                    },
                }));
            }
        };
        if (process.env.NEXT_PUBLIC_APP_ENV === 'prod') {
            setupPixel();
        }
    }, [pixel, trackEvent]);
    useEffect(() => {
        if (!pixel) {
            return;
        }
        const handleChange = () => {
            pixel.pageView();
            addedToCartRef.current = false;
        };
        handleChange();
        router.events.on('routeChangeComplete', handleChange);
        return () => {
            router.events.off('routeChangeComplete', handleChange);
        };
    }, [pixel, router.events]);
    return pixel;
};

export const useTracking = () => {
    return useContext(PixelContext);
};

export const TrackingProvider: React.FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
    const pixel = useInitPixel();
    return <PixelContext.Provider value={pixel}>{children}</PixelContext.Provider>;
};
